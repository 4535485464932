import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';
import Channel from './components/Channel';
import RichText from './components/Inputs/RichText';
import { Howl, Howler } from 'howler';
import Home from './Home';
import Header from './components/Menus/Header';
import { BrowserRouter as Router, Routes, Route, HashRouter} from 'react-router-dom';
import Login from './Login';
import Dash from './Dash';
import Post from './Post'
import { auth } from './firebaseconfig'


function App() {
  const[loggedIn, setLoggedIn] = useState(true);
  const[user, setUser] = useState(null);
  let logo = require('./logo.png')
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(userAuth=>{
      const user = {
        uid: userAuth.uid,
        email: userAuth.email
      }
      if(userAuth){
        console.log(userAuth);
        setUser(user);
      }else{
        setUser(null)
      }
    })
    return unsubscribe
  }, [])

  return (
    <div className="App">
      <Router >
        <Header logo={logo}/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dash" element={user ? <Dash /> : <Login />} />
          <Route path="/post/:id" element={<Post />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
