import React from 'react';
import Button from '../Inputs/Button';
import './Card.css'
export default function Card(props){
    return(
        <div className="card">
            <div className="card-image" style={{backgroundImage: `url(${props.img})`, backgroundPosition: "80%"}}/>
            <p><strong>{props.text}</strong></p>
            <Button style={props.buttonStyle} text="Read More" onClick={props.onClick}/>
        </div>
    )
}