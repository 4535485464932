import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { db } from './firebaseconfig';
import parse from 'html-react-parser'
import { collection, getDoc, doc } from 'firebase/firestore'
import ParallaxView from './components/Image/ParallaxView';
import Parallax from 'rellax'
import './css/Post.css';

import RichText from './components/Inputs/RichText';

export default function Post(props){
    const { id } = useParams();
    const[postTitle, setPostTitle] = useState("");
    const[postBody, setPostBody] = useState("");
    const[postImage, setPostImage] = useState();
    const postsCollectionRef = collection(db, "Posts");
    //getting post on firestore
    
    const docRef = doc(db, "Posts", id)
    getDoc(docRef).then((doc) => {
        setPostTitle(doc.data().title);
        setPostBody(doc.data().postBody);
        setPostImage(doc.data().postImg);
    })
    return(
        <div className="homepage">
            <div className="post-container">
                <div className="image-container" style={{backgroundImage: `url('${postImage}')`}} />
                <h1>{postTitle}</h1>
                {parse(postBody)}
            </div>
        </div>
    )
}