import React from "react";
import { Link } from "react-router-dom";
import './Header.css'
export default function Header(props){
    return(
        <div className="header-container">
            <div className="logo-container">
                <Link to="/">
                    <img className="logo-img" src={props.logo}/>
                </Link>
                <p>Jesse Devs</p>
            </div>
            {props.children}
            <Link style={{textDecoration: "none", fontWeight: "bolder", color: "white"}} to="/">Posts</Link>
            {/*{props.loggedIn} ? <Link to="/dash">Dashboard</Link> : <div></div>*/}
        </div>
    )
}