import React, { useState, useEffect } from "react";
import Card from "./components/Card/Card";
import { collection, getDocs } from 'firebase/firestore'
import { db } from './firebaseconfig'
import { useNavigate } from 'react-router-dom';
import Grid from "./components/Grid/Grid";
import './css/Home.css'
import './css/Inputs.css'
export default function Home(props){

    let navigate = useNavigate();
    const[postList, setPostList] = useState([]);
    const postsCollectionRef = collection(db, "Posts");
    //getting posts on firestore
    useEffect(() => {
        const getPosts = async () => {
            const data = await getDocs(postsCollectionRef);
            setPostList(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
        };
        getPosts();
    }, [])

    return(
        <div className="homepage">
            <Grid overflow="scroll" width="90" height="90" gap="20" rowDimensions="520px 520px 520px 520px" columnDimensions="5fr 5fr 5fr">
                {postList.map((post) => {
                    return <Card buttonStyle="button" text={`${post.title}`} img={`${post.postImg}`} onClick={() => navigate(`/post/${post.id}`)}/>
                })}
            </Grid>
            
        </div>
    )
}