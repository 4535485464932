import React, { useState } from 'react';
import Button from './components/Inputs/Button';
import RichText from './components/Inputs/RichText';
import TextInput from './components/Inputs/TextInput';
import { auth } from './firebaseconfig';
import { addDoc, collection } from 'firebase/firestore';
import { db } from './firebaseconfig';
import { useNavigate } from 'react-router-dom';
import './css/Inputs.css';
import "./css/Dash.css";
export default function Dash(props){
    const[title, setTitle] = useState("");
    const[postBody, setPostBody] = useState("");
    const[postImg, setPostImg] = useState("");

    let navigate = useNavigate();
    const postCollectionRef = collection(db, "Posts")
    const createPost = async () =>{
        await addDoc(postCollectionRef, {title, postBody, postImg});
        navigate("/");
    }

    const signOutOfBlog = () =>{
        auth.signOut();
        navigate("/login");
    }

    return(<div className="dashpage">
        <TextInput placeholder="Title" onChange={(event) => setTitle(event.target.value)}/>
        <RichText callBack={setPostBody}/>
        <TextInput placeholder="Tags" />
        <TextInput placeholder="Picture Url" onChange={(event) => setPostImg(event.target.value)}/>
        <div className="button-container">
            <Button text="Submit" style="button" onClick={createPost}/>
            <Button text="Sign Out" style="button" onClick={signOutOfBlog}/>
        </div>
    </div>)
}