import React from 'react';
import './Image.css'
export default function Image(props){
    return(
        <div className={props.style}>
            <p style={{color: `${props.textColor}`, fontSize: `${props.fontSize}`, fontWeight: `${props.fontWeight}`}}>{props.topText}</p>
            <img className={props.animation} style={{height: `${props.height}`, width: `${props.width}`}} src={props.src}/>
            <p style={{color: `${props.textColor}`, fontSize: `${props.fontSize}`, fontWeight: `${props.fontWeight}`}}>{props.bottomText}</p>
        </div>
    )
}