import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyB1b4x-_TgfYMZfbOkz765yBe_D6vuadck",
    authDomain: "jesseblogs-3fa84.firebaseapp.com",
    projectId: "jesseblogs-3fa84",
    storageBucket: "jesseblogs-3fa84.appspot.com",
    messagingSenderId: "427302764951",
    appId: "1:427302764951:web:761a752be8ddeb416344d2",
    measurementId: "G-PD8RHZRSJ3"
  };

  const app = firebase.initializeApp(firebaseConfig);
  const db = firebase.firestore();
  const auth = firebase.auth();
  export {auth};
  export { db };