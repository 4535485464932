import React, { useRef } from 'react';
import Button from './components/Inputs/Button';
import { auth } from './firebaseconfig'
import TextInput from './components/Inputs/TextInput';
import './css/Inputs.css'
import './css/Login.css'
import { useNavigate } from 'react-router-dom'
import Image from './components/Image/Image';

export default function Login(props){
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    let navigate = useNavigate();
    let logo = require('./logo.png');
    const logIn = e =>{
        e.preventDefault();
        auth.signInWithEmailAndPassword(
            emailRef.current.value,
            passwordRef.current.value
        ).then(user => {
            console.log(user);
            navigate("/dash");
        }).catch(err => {
            console.log(err);
        })
    }

    return(
        
        <div className="login-page">
            <div className="login-container">
                <Image animation="rotate-on-hover" src={logo} height="300px" width="300px" bottomText="Jesse Devs" textColor="white" fontSize="48px"/>
                <input className="text-input" ref={emailRef} type='email' placeholder='Email'/>
                <input className="text-input" ref={passwordRef} type='password'placeholder='Password'/>
                <Button style="button" onClick={logIn} text="Sign In"/>
            </div>
        </div>
    )
}